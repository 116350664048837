/* Third Party -----------------------------------------------------*/

import $ from 'jquery';

let GoogleMapsLoader = require('google-maps');

let markers = [];
let map;
let $body   = $('body');
let markerClusterer;

GoogleMapsLoader.KEY = ($body.attr('data-maps-key') && $body.attr('data-maps-key').length) ? $body.attr('data-maps-key') : 'AIzaSyBqNwL9N3W2owxDz_Pty0jOiWPpt3gW_-0';

GoogleMapsLoader.load(function (google) {
    createMap();
});

$body.on('click', '.js-close-infobox', function (e) {
    e.preventDefault();
    $('.js-infobox').removeClass('active');
});

function createMap() {
    let $map = $('.js-map');
    if ($map.length > 0) {
        let mapOptions = {
            zoom            : 10,
            center          : new google.maps.LatLng(parseFloat($map.attr('data-lat')), parseFloat($map.attr('data-lng'))),
            styles          : window.mapStyles,
            backgroundColor : 'hsla(0, 0%, 0%, 0)',
            disableDefaultUI: true,
        };
        map            = new google.maps.Map($map[0], mapOptions);

        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(parseFloat($map.attr('data-lat')), parseFloat($map.attr('data-lng'))),
            map     : map,
            icon    : {
                path        : "M12 0C5.373 0 0 5.28 0 11.791 0 18.303 11.374 36 12 36c.601 0 12-17.697 12-24.209C24 5.28 18.628 0 12 0",
                fillColor   : `#${$('.js-colour').attr('data-colour')}`,
                fillOpacity : 1,
                anchor      : new google.maps.Point(0, 0),
                strokeWeight: 0,
                scale       : 1
            }
        });

        marker.addListener('click', function () {
            window.location = $map.attr('data-link');
        });

        $('.js-zoom-in').on('click', function (e) {
            e.preventDefault();
            map.setZoom(map.getZoom() + 1);
        });

        $('.js-zoom-out').on('click', function (e) {
            e.preventDefault();
            map.setZoom(map.getZoom() - 1);
        });

    }
}

