/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import slick from 'slick-carousel';
import './service';
import './locations';

require('./filterJS.js');

/* Variables -------------------------------------------------------*/

let $body = $('body');

/*-------------------------------------------------------------------
Read More Toggle
--------------------------------------------------------------------*/

$body.on('click', '.js-read-more', function (e) {
    e.preventDefault();
    let $this = $(this);
    if (!$this.hasClass('active')) {
        $this.prev('.js-hidden').slideDown();
        $this.addClass('active');
        $this.find('b').text('READ LESS');
    } else {
        $this.prev('.js-hidden').slideUp();
        $this.removeClass('active');
        $this.find('b').text('READ MORE');
    }
});

/*-------------------------------------------------------------------
Navburger
--------------------------------------------------------------------*/

$body.on('click', '.js-navburger', function (e) {
    e.preventDefault();
    $body.toggleClass('mobile-menu-active');
});

/*-------------------------------------------------------------------
Accordion
--------------------------------------------------------------------*/
$body.on('click', '.js-accordion', function (e) {
    e.preventDefault();
    let $this = $(this);
    if (!$this.hasClass('active')) {
        $this.next('.js-hidden').slideDown();
        $this.addClass('active');
        $this.find('b').text('READ LESS');
    } else {
        $this.next('.js-hidden').slideUp();
        $this.removeClass('active');
        $this.find('b').text('READ MORE');
    }
});

/*-------------------------------------------------------------------
Member Slider
--------------------------------------------------------------------*/

let $memberSlider = $('.js-members-slider');

if ($memberSlider.find('.profileWrap__members__slider__item').length > 5) {
    $memberSlider.slick({
        slidesToShow : 5,
        centerMode   : true,
        centerPadding: '0',
        dots         : false,
        arrows       : true,
        prevArrow    : `<a href="#" class="profileWrap__members__slider__control profileWrap__members__slider__control--prev"><svg width="7" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M.17 6.407a.572.572 0 0 1 0-.814L5.564.244a.847.847 0 0 1 1.19 0 .83.83 0 0 1 0 1.18L2.14 6l4.614 4.575a.831.831 0 0 1 0 1.18.847.847 0 0 1-1.19 0L.17 6.408z" fill="#C2BFDA" fill-rule="evenodd"/></svg></a>`,
        nextArrow    : `<a href="#" class="profileWrap__members__slider__control profileWrap__members__slider__control--next"><svg width="7" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6.83 5.593a.572.572 0 0 1 0 .814l-5.394 5.349a.847.847 0 0 1-1.19 0 .83.83 0 0 1 0-1.18L4.86 6 .246 1.425a.831.831 0 0 1 0-1.18.847.847 0 0 1 1.19 0L6.83 5.592z" fill="#C2BFDA" fill-rule="evenodd"/></svg></a>`,
    });
} else {
    $memberSlider.addClass('noSlide');
}

/*-------------------------------------------------------------------
Agenda Slider
--------------------------------------------------------------------*/

let $agendaSlider = $('.js-agenda-slider');

$agendaSlider.slick({
    slidesToShow: 1,
    dots        : false,
    arrows      : true,
    prevArrow   : `<a href="#" class="agendaDates__slider__control agendaDates__slider__control--prev"><svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M.34 12.815a1.144 1.144 0 0 1 0-1.63L11.128.49a1.694 1.694 0 0 1 2.38 0 1.661 1.661 0 0 1 0 2.36L4.282 12l9.227 9.15c.656.652.656 1.709 0 2.36a1.694 1.694 0 0 1-2.38 0L.34 12.815z" fill="#A09CC4" fill-rule="evenodd"/></svg></a>`,
    nextArrow   : `<a href="#" class="agendaDates__slider__control agendaDates__slider__control--next"><svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M13.66 11.185c.454.45.454 1.178 0 1.63L2.872 23.51a1.694 1.694 0 0 1-2.38 0 1.661 1.661 0 0 1 0-2.36L9.718 12 .492 2.85a1.663 1.663 0 0 1 0-2.36 1.694 1.694 0 0 1 2.38 0L13.66 11.185z" fill="#A09CC4" fill-rule="evenodd"/></svg></a>`,
    asNavFor    : '.js-breakdown-slider'
});

let $breakdownSlider = $('.js-breakdown-slider');

$breakdownSlider.slick({
    slidesToShow  : 1,
    dots          : false,
    arrows        : false,
    asNavFor      : '.js-agenda-slider',
    fade          : true,
    speed         : 800,
    adaptiveHeight: true
});

/*-------------------------------------------------------------------
Filtering
--------------------------------------------------------------------*/

if ($('#js-rendered').length) {

    $.getJSON($('#js-rendered').attr('data-api-url'), function (response) {
        console.log(response);

        let FJS = FilterJS(response, '#js-rendered', {
            template  : '#js-template',
            search    : { fields: ['name', 'title'], ele: '#js-searchbox' },
            pagination: {
                container   : '#js-pagination',
                visiblePages: 3,
                perPage     : {
                    values   : [6],
                    container: '#per_page'
                },
            },
            callbacks : {
                afterFilter: function (result) {
                    if (result.length < 6) {
                        $('#js-pagination').hide();
                    } else {
                        $('#js-pagination').show();
                    }
                }
            },
        });

    })
}

$('#MemberLoginForm_LoginForm_Email,#LostPasswordForm_lostPasswordForm_Email').attr('placeholder', 'Email');
$('#MemberLoginForm_LoginForm_Password').attr('placeholder', 'Password');
$('#ChangePasswordForm_ChangePasswordForm_OldPassword').attr('placeholder', 'Old Password');
$('#ChangePasswordForm_ChangePasswordForm_NewPassword1').attr('placeholder', 'New Password');
$('#ChangePasswordForm_ChangePasswordForm_NewPassword2').attr('placeholder', 'Confirm New Password');

/*------------------------------------------------------------------
 Sticky Header
 ------------------------------------------------------------------*/

let $header = $('.header');
let didScroll;

if ($header.length > 0) {
    document.addEventListener('wheel', (evt) => {
        didScroll = true;
        fixedHeader();
    }, {
        capture: true,
        passive: true
    });
}

setInterval(function () {
    fixedHeader();
}, 220);

function fixedHeader() {
    if ($(window).scrollTop() >= 40) {
        $body.addClass('fixedHeader');
    } else {
        $body.removeClass('fixedHeader');
    }
}